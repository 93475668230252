<template>
    <div class="main-wrapper">
        <el-scrollbar class="table-content">
            <div class="top-tab" v-if="$route.query.method === 'stuTrain' || $route.query.method === 'teaTrain'">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="customBreadcrumb">
                    <el-breadcrumb-item>训练中心</el-breadcrumb-item>
                    <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                    <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                    <el-breadcrumb-item>查看详情</el-breadcrumb-item>
                </el-breadcrumb>
                <el-button type="primary" @click="toBack" class="go-back">返回</el-button>
            </div>
<!--            信息流推广详情-->
            <template v-if="moduleId === 34 || moduleId === 88">
                <el-table :data="infoList" class="customTable"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column label="考试内容">
                        <template slot-scope="scope">信息流推广</template>
                    </el-table-column>
                    <el-table-column prop="title" label="计划名称">
                        <template slot-scope="scope">
                            <span>{{scope.row.title?scope.row.title:'暂无数据'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="budget" label="日预算" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.budget?scope.row.budget==0?'不限':scope.row.budget:'暂无数据'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="展现数">
                        <template slot-scope="scope">
                            {{scope.row.show_num  ?scope.row.show_num : '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="点击数" align="center">
                        <template slot-scope="scope">
                            {{scope.row.click_num  ?  scope.row.click_num :'-' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="转化数" align="center">
                        <template slot-scope="scope">
                            {{scope.row.conversion_num ? scope.row.conversion_num :'-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_fee" label="总费用" align="center">
                        <template slot-scope="scope">
                            {{scope.row.total_fee ? scope.row.total_fee :'-' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="满分点击量" align="center">
                        <template slot-scope="scope">
                            {{scope.row.full_marks ? scope.row.full_marks : '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                        <template slot-scope="scope">
                            {{scope.row.final_score ? scope.row.final_score : 0}}
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <!-- 社群推广详情-->
            <template v-else-if="moduleId === 35">
                <el-table :data="groupList" v-if="groupList.length > 0" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="content" label="考试内容"></el-table-column>
                    <el-table-column label="设置填写问题" prop="question" align="left"></el-table-column>
                    <el-table-column label="学生提交答案" prop="student" align="left">
                        <template slot-scope="scope">
                            <span style="color: #1cb064;cursor: pointer;" v-if="scope.row.content =='image'" @click="preview(scope.row.student)">查看详情</span>
                            <span v-else>{{scope.row.student}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" prop="normal" align="left"></el-table-column>
                    <el-table-column label="得分" prop="score" align="left">
                        <template slot="header" slot-scope="scope">
                            <span>得分（{{score}}）</span>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
<!--            网店商品定价管理-->
            <template v-else-if="moduleId === 38">
                <el-table :data="infoList" class="customTable"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column label="考试内容">
                        <template slot-scope="scope">
                            <div v-if="scope.$index === 0">网店商品定价管理</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品信息">
                        <template slot-scope="scope">
                            <div class="goods-info">
                                <div class="good-img" @click="toGoodDetail(scope.row.goods_id)">
                                    <img :src="`${hosturl}${scope.row.goods_img}`" alt="">
                                </div>
                                <div class="info-right text-overflow-2" @click="toGoodDetail(scope.row.goods_id)">{{scope.row.goods_title}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sales_price" label="销售价" align="center"></el-table-column>
                    <el-table-column v-if="$route.query.method !== 'stuTrain'" label="得分答案" align="center">
                        <template slot-scope="scope">
                            {{scope.row.min_price}} ~ {{scope.row.max_price}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                        <template slot-scope="scope">
                            {{scope.row.score ? scope.row.score : 0}}
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <!--社交电商-->
            <template v-else-if="moduleId === 36">
                <el-table :data="socialECommerceList" v-if="socialECommerceList" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column label="考试内容">
                        <template slot-scope="scope">
                            <div>社交电商（{{scope.row.type_name}}）</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="学生提交答案" align="left">
                        <template slot-scope="scope">
                            <div class="link-box" v-if="scope.row.cover">
                                <el-link type="success" :underline="false" @click="viewGoodsImg" class="view-detail">查看详情</el-link>
                            </div>
                            <div class="link-box" v-else>
                                <el-link type="success" :underline="false"  class="view-detail">未提交</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column  align="left">
                        <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                        <template slot-scope="scope">
                            <span>{{scope.row.final_score?scope.row.final_score:0}}分</span>
                        </template>
                    </el-table-column>
                    <el-table-column  align="left">
                        <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                        <template slot-scope="scope">
                            <span>{{scope.row.final_score?scope.row.final_score:0}}分</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-dialog title="商品主图" :visible.sync="goodsImgDialog" width="50%" @close="handleCloseGoodsImgDialog" custom-class="green-dialog goods-img-dialog">
                    <div class="text-content" v-for="(item,index) in socialECommerceList" :key="index">
                        <span>{{item.title}}</span>
                        <span style="margin-top:5px;">{{item.author}}</span>
                        <span style="margin-top:5px;">平台:{{item.platform==1?'公众号':'微博'}}</span>
                        <span v-html="item.content"></span>
                    </div>

                    <div class="upload-file-content">

                        <div class="big-img">
                            <img :src="bigImg" alt="">
                        </div>
                    </div>
                </el-dialog>
            </template>
            <!--移动营销与运营-->
                <template v-else-if="moduleId === 37">
                <el-table :data="JDDirectList" v-if="JDDirectList.length > 0" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px">
                   <el-table-column prop="content" label="考试内容"></el-table-column>
                    <el-table-column label="设置填写问题" prop="question" align="left"></el-table-column>
                    <el-table-column label="学生提交答案" prop="student" align="left">
                        <template slot-scope="scope">
                            <span style="color: #1cb064;cursor: pointer;" v-if="scope.row.content =='image'" @click="preview(scope.row.student)">查看详情</span>
                            <span v-else>{{scope.row.student}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" prop="normal" align="left"></el-table-column>
                    <el-table-column label="得分" prop="score" align="left">
                        <template slot="header" slot-scope="scope">
                            <span>得分（{{score}}）</span>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <template v-else>
                <el-table :data="cyberInfo" class="customTable"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column prop="name" label="考试内容"></el-table-column>
                    <el-table-column prop="project_name" label="计划名称"></el-table-column>
                    <el-table-column prop="daily_budget" label="日预算"></el-table-column>
                    <el-table-column prop="show_num" label="展现数"></el-table-column>
                    <el-table-column prop="click_num" label="点击数"></el-table-column>
                    <el-table-column label="转化数">
                        <template #default="scope">
                            {{Math.floor(scope.row.click_num * scope.row.tran_rate * 0.01)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="total_cost" label="总费用"></el-table-column>
                    <el-table-column label="满分点击量">
                        <template #default="scope">
                            {{scope.row.max_click_num ? scope.row.max_click_num : ""}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                        <template slot-scope="scope">
                            {{scope.row.score ? scope.row.score : ""}}
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </el-scrollbar>
        <el-dialog title="商品主图" :visible.sync="materialImgDialog" width="764px" @close="handleCloseMaterialImgDialog" custom-class="green-dialog goods-img-dialog">
            <div class="upload-file-content">
                <div class="big-img">
                    <img :src="materialImg" alt="">
                </div>
            </div>
        </el-dialog>
         <!-- 移动营销与运营图片弹窗 -->
        <!-- 外层的遮罩 v-if用来控制显示隐藏 点击事件用来关闭弹窗 -->
        <div class="mask" v-if="videoState === true" @click="masksCloseFun"></div>
        <!-- 弹窗 -->
        <div class="videomasks" v-if="videoState === true">
            <img :src="img" alt="" />
            <div class="comment-top-btn" @click="close"></div>
        </div>
    </div>
</template>

<script>
    import {networkMarketingDetail, teacheropmTrainingLogData, teacheropmExamData, pricing_managementTrainDetail, pricing_managementTeachGetAnswer,grouppromotionTrainDetail,grouppromotionTeachGetAnswer,operationsTrainDetail,operationsTeachGetAnswer} from "@/utils/apis"
    import {searchAnswerDetail} from "@/utils/searchPromotion";
    import {errMsg} from "@/utils/common";

    export default {
        name: "cyberCustomerDetail",
        data() {
            return {
                moduleId: Number(this.$route.query.moduleId),
                cyberInfo: [],

                infoList: [], // 信息流列表、网店商品定价管理
                infoScore: 0, // 信息流总分、网店商品定价管理
                groupList:[], // 社群推广
                hosturl: localStorage.getItem('hosturl'),
                //社交电商列表
                socialECommerceList: [],
                //商品主图弹窗
                goodsImgDialog: false,
                //大图
                bigImg: '',
                //移动营销与运营
                JDDirectList: [],
                role: localStorage.getItem('role'),
                //查看素材弹窗
                materialImgDialog: false,
                materialImg: '',
                maImg:'',
                student_score: 0,//移动营销与运营的学生得分
                 score:null,
                videoState:false,
                img:''
            }
        },
        created() {
            this.getList();
        },
        methods: {
            toBack() {
                this.$router.go(-1)
            },
            // 去商品详情
            toGoodDetail(id) {
                let routeDate = this.$router.resolve({
                    path: '/goods/detail',
                    query:{
                        g_id: id,
                        type: 1
                    }
                })
                window.open(routeDate.href, '_blank')
            },
              // 移动运营与营销弹窗方法
            preview(val){
                this.videoState = true
                this.img = val
            },
            masksCloseFun(){
                this.videoState = false
            },
             close(){
                this.videoState = false
            },

            getList() {
                if (this.moduleId === 34 || this.moduleId===88) { // 信息流推广详情
                    let params = {}
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                        params.train_id = this.$route.query.trainId
                        teacheropmTrainingLogData(params).then((res) => {
                            if (res.data.length > 0) {
                                this.infoList = res.data
                                this.infoScore = res.data[0].final_score
                            }
                        })
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.student_id = this.$route.query.id // 学生id
                        params.exam_id = this.$route.query.examId
                        params.module_id = this.$route.query.moduleId
                        teacheropmExamData(params).then((res) => {
                            if (res.data.length > 0) {
                                this.infoList = res.data
                                this.infoScore = res.data[0].final_score
                            }else{
                               this.infoList.push('信息流推广')
                            }
                        })
                    }
                }
                if (this.moduleId === 38) { // 网店商品定价管理
                    let params = {}
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                        params.train_id = this.$route.query.trainId
                        pricing_managementTrainDetail(params).then((res) => {
                            this.infoList = res.data.list
                            this.infoScore = res.data.totalScore
                        })
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.eaxm_id = this.$route.query.examId
                        params.user_id = this.$route.query.id
                        pricing_managementTeachGetAnswer(params).then((res) => {
                            this.infoList = res.data.list
                            this.infoScore = res.data.totalScore
                        })
                    }
                }
                if (this.moduleId === 36) { //社交电商
                    let params = {}
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                        params.train_id = this.$route.query.trainId
                        teacheropmTrainingLogData(params).then((res) => {
                            if (res.data.length > 0) {
                                this.socialECommerceList = res.data;
                            }
                        })
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.student_id = this.$route.query.id // 学生id
                        params.exam_id = this.$route.query.examId
                        params.module_id = this.$route.query.moduleId
                        teacheropmExamData(params).then((res) => {
                            if (res.data.length > 0) {
                                this.socialECommerceList = res.data;
                                this.infoScore = res.data[0].final_score
                            }else{
                                this.socialECommerceList.push(res.data)
                            }
                        })
                    }
                }
                if (this.moduleId === 37) { //移动营销与运营
                    let params = {}
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                        // params.train_id = this.$route.query.trainId;
                         params.student_id = this.$route.query.id; // 学生id
                       networkMarketingDetail(params).then((res) => {
                            // this.dataProcessing(res);
                            // this.infoScore = res.data.totalScore;
                            // this.student_score = res.data.studentScore;
                             this.JDDirectList = res.data.list
                                 this.score = res.data.score
                        })
                    }
                    else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                       params.student_id = this.$route.query.id; // 学生id
                        params.exam_id = this.$route.query.examId;
                        networkMarketingDetail(params).then((res) => {
                            // this.dataProcessing(res);
                            // this.infoScore = res.data.totalScore;
                            // this.student_score = res.data.studentScore;
                               this.JDDirectList = res.data.list
                                 this.score = res.data.score
                                 console.log(res,'666')
                        })
                    }
                }

                if (this.moduleId ===35) {
                    let params = {}
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                        // params.train_id = this.$route.query.trainId
                          params.student_id = this.$route.query.id; // 学生id
                        networkMarketingDetail(params).then((res) => {
                            this.groupList = res.data.list
                                 this.score = res.data.score
                            // let arr_group = [
                            //     {
                            //         content:'社群推广',
                            //         questions:'群聊名称',
                            //         answer:res.data.data.group_chat_name,
                            //         answerRight:res.data.data.group_chat_name_answer,
                            //         score:res.data.data.group_chat_name_score
                            //     },
                            //     {
                            //         questions:'备注',
                            //         answer:res.data.data.mark,
                            //         answerRight:res.data.data.mark_answer,
                            //         score:res.data.data.mark_score
                            //     },
                            //     {
                            //         questions:'群公告',
                            //         answer:res.data.data.group_notice,
                            //         answerRight:res.data.data.group_notice_answer,
                            //         score:res.data.data.group_notice_score
                            //     }
                            // ]
                            // this.groupList=arr_group;
                            // this.infoScore = res.data.totalScore

                        })
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.student_id = this.$route.query.id; // 学生id
                        params.exam_id = this.$route.query.examId;
                        params.module_id = this.$route.query.moduleId
                        networkMarketingDetail(params).then((res) => {
                           this.groupList = res.data.list
                                 this.score = res.data.score
                            // console.log(res,'jiaos')
                            // let arr_group = [
                            //     {
                            //         content:'社群推广',
                            //         questions:'群聊名称',
                            //         answer:res.data.data.group_chat_name,
                            //         answerRight:res.data.data.group_chat_name_answer,
                            //         score:res.data.data.group_chat_name_score
                            //     },
                            //     {
                            //         questions:'备注',
                            //         answer:res.data.data.mark,
                            //         answerRight:res.data.data.mark_answer,
                            //         score:res.data.data.mark_score
                            //     },
                            //     {
                            //         questions:'群公告',
                            //         answer:res.data.data.group_notice,
                            //         answerRight:res.data.data.group_notice_answer,
                            //         score:res.data.data.group_notice_score
                            //     }
                            // ]
                            // this.groupList=arr_group;
                            // this.infoScore = res.data.totalScore
                        })
                    }
                }
                if (Number(this.moduleId) === 33) {
                    this.searchStuAnswerDetail();
                }
            },
            async searchStuAnswerDetail() {
                try {
                    const param  = {
                        stu_id: this.$route.query.id,
                        com_id: this.$route.query.examId
                    }
                    const {code, data, msg} = await searchAnswerDetail(param)
                    if (code === 200) {
                        this.cyberInfo = data.list
                        this.infoScore = data.score
                    }
                    console.log("data", data)
                } catch (e) {
                    errMsg(e)
                }
            },
            //数据处理
            dataProcessing(data) {
                //设置填写问题
                let media_type_question = data.data.data.question.media_type_question;//媒体类型
                let promote_type_question = data.data.data.question.promote_type_question;//推广计划类型

                //去掉区域的字母
                if (data.data.data.directional.area !== 'all') {
                    let reg = /[a-zA-Z]+/;
                    let result;
                    while ( result = data.data.data.directional.area.match(reg)) { //判断str.match(reg)是否没有字母了
                        data.data.data.directional.area = data.data.data.directional.area.replace(result[0], ''); //替换掉字母  result[0] 是 str.match(reg)匹配到的字母
                    }
                }
                if (data.data.data.directional.area_answer && data.data.data.directional.area_answer !== 'all') {
                    let reg = /[a-zA-Z]+/;
                    let result;
                    while ( result = data.data.data.directional.area_answer.match(reg)) { //判断str.match(reg)是否没有字母了
                        data.data.data.directional.area_answer = data.data.data.directional.area_answer.replace(result[0], ''); //替换掉字母  result[0] 是 str.match(reg)匹配到的字母
                    }
                }

                //创意-媒体类型
                let media_type = '';
                if (data.data.data.creative.media_type === 1) {
                    media_type = '京东落地页';
                } else {
                    media_type = '一号店落地页';
                }

                //公共部分正确答案
                let media_type_answer = '';
                if (data.data.data.creative.media_type_answer && data.data.data.creative.media_type_answer === 1) {
                    media_type_answer = '京东落地页';
                } else {
                    media_type_answer = '一号店落地页';
                }
                let plan_name_answer = '';
                if (data.data.data.plan.plan_name_answer) {
                    plan_name_answer = data.data.data.plan.plan_name_answer;
                }
                let put_position_answer = '';
                if (data.data.data.directional.put_position_answer) {
                    put_position_answer = JSON.parse(data.data.data.directional.put_position_answer).sku_name;
                }
                let directional_name_answer = '';
                if (data.data.data.directional.directional_name_answer) {
                    directional_name_answer = data.data.data.directional.directional_name_answer;
                }
                let click_unit_price_answer = '';
                if (data.data.data.directional.click_unit_price_answer) {
                    click_unit_price_answer = data.data.data.directional.click_unit_price_answer;
                }
                let advertising_name_answer = '';
                if (data.data.data.directional.advertising_name_answer) {
                    advertising_name_answer = data.data.data.directional.advertising_name_answer;
                }
                let promote_link_answer = '';
                if (data.data.data.creative.promote_link_answer) {
                    promote_link_answer = data.data.data.creative.promote_link_answer;
                }
                let sku_id_answer = '';
                if (data.data.data.creative.sku_id_answer) {
                    sku_id_answer = data.data.data.creative.sku_id_answer;
                }
                let material_title_answer = '';
                if (data.data.data.creative.material_title_answer) {
                    material_title_answer = data.data.data.creative.material_title_answer;
                }
                let arr_publish = [
                    {
                        question: '推广计划名称',
                        stu_answers: data.data.data.plan.plan_name,
                        answers: plan_name_answer,
                        score: data.data.data.plan.plan_name_score
                    },
                    {
                        question: '投放位置',
                        stu_answers: JSON.parse(data.data.data.directional.put_position).sku_name,
                        answers: put_position_answer,
                        score: data.data.data.directional.put_position_score
                    },
                    {
                        question: '定向名称',
                        stu_answers: data.data.data.directional.directional_name,
                        answers: directional_name_answer,
                        score: data.data.data.directional.directional_name_score,
                    },
                    {
                        question: '选择区域',
                        stu_answers: '',
                        answers: '',
                        score: data.data.data.directional.area_score,
                    },
                    {
                        question: '点击出价',
                        stu_answers: data.data.data.directional.click_unit_price + '元',
                        answers: click_unit_price_answer + '元',
                        score: data.data.data.directional.click_unit_price_score,
                    },
                    {
                        question: '广告名称',
                        stu_answers: data.data.data.directional.advertising_name,
                        answers: advertising_name_answer,
                        score: data.data.data.directional.advertising_name_score,
                    },
                    {
                        question: '媒体类型',
                        stu_answers: media_type,
                        answers: media_type_answer,
                        score: data.data.data.creative.media_type_score,
                    },
                    {
                        question: '推广链接',
                        stu_answers: data.data.data.creative.promote_link,
                        answers: promote_link_answer,
                        score: data.data.data.creative.promote_link_score,
                    },
                    {
                        question: '主推SKUID',
                        stu_answers: data.data.data.creative.sku_id,
                        answers: sku_id_answer,
                        score: data.data.data.creative.sku_id_score,
                    },
                    {
                        question: '素材标题',
                        stu_answers: data.data.data.creative.material_title,
                        answers: material_title_answer,
                        score: data.data.data.creative.material_title_score,
                    },
                    {
                        question: '素材上传',
                        stu_answers: '',
                        answers: '上传素材图片',
                        score: data.data.data.creative.material_score,
                    }
                ]
                if (data.data.data.directional.area === 'all') {
                    arr_publish[3].stu_answers = '全部区域';
                } else {
                    arr_publish[3].stu_answers = data.data.data.directional.area;
                }
                if (data.data.data.directional.area_answer === 'all') {
                    arr_publish[3].answers = '全部区域';
                } else {
                    arr_publish[3].answers = data.data.data.directional.area_answer;
                }
                if (data.data.data.directional.mark) {
                    let mark_answer = '';
                    if (data.data.data.directional.mark_answer) {
                        mark_answer = data.data.data.directional.mark_answer;
                    }
                    let obj = {
                        question: '广告备注',
                        stu_answers: data.data.data.directional.mark,
                        answers: mark_answer,
                        score: data.data.data.directional.mark_score,
                    }
                    arr_publish.splice(6, 0, obj);
                }
                //素材上传
                this.materialImg = data.data.data.creative.material;

                //正确答案
                let plan_media_type_answer = '';//媒体类型
                let promote_type_answer = '';//推广计划类型
                let offer_type_answer = '';//出价方式
                let put_date_answer = '';//投放日期
                let put_time_answer = '';//每日投放时间
                let limit_answer = '';//每日限额
                let advanced_rules_answer = '';//高级规则
                //腾讯
                if (data.data.data.plan.media_type_answer && data.data.data.plan.media_type_answer === 1) {
                    plan_media_type_answer = '腾讯';
                    if (data.data.data.plan.promote_type_answer === 1) {
                        promote_type_answer = '京东定向推广计划';
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'CPM';
                        }
                        if (data.data.data.plan.offer_type_answer === 3) {
                            offer_type_answer = 'eUV';
                        }
                        if (data.data.data.plan.put_date_answer) {
                            put_date_answer = (data.data.data.plan.put_date_answer).split('#')[0] + '至' + (data.data.data.plan.put_date_answer).split('#')[1];
                        }
                        if (data.data.data.plan.put_time_answer) {
                            if (data.data.data.plan.put_time_answer === 'all') {
                                put_time_answer = '全时间段投放';
                            } else {
                                put_time_answer = '在特定时间投放';
                            }
                        }
                        if (data.data.data.plan.limit_answer === '0') {
                            limit_answer = '不限制';
                        } else {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                    }
                    if (data.data.data.plan.promote_type_answer === 2) {
                        promote_type_answer = '无线广告推广计划';
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'CPM';
                        }
                        if (data.data.data.plan.limit_answer === '0') {
                            limit_answer = '不限制';
                        } else {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                        if (data.data.data.plan.advanced_rules_answer === 1) {
                            advanced_rules_answer = '匀速投放';
                        }
                        if (data.data.data.plan.advanced_rules_answer === 2) {
                            advanced_rules_answer = '加速投放';
                        }
                    }
                    if (data.data.data.plan.promote_type_answer === 3) {
                        promote_type_answer = '朋友圈计划';
                    }
                    if (data.data.data.plan.promote_type_answer === 4) {
                        promote_type_answer = 'PC广告推广计划';
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'CPM';
                        }
                        if (data.data.data.plan.limit_answer === '0') {
                            limit_answer = '不限制';
                        } else {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                        if (data.data.data.plan.advanced_rules_answer === 1) {
                            advanced_rules_answer = '匀速投放';
                        }
                        if (data.data.data.plan.advanced_rules_answer === 2) {
                            advanced_rules_answer = '加速投放';
                        }
                    }
                }
                //头条
                if (data.data.data.plan.media_type_answer && data.data.data.plan.media_type_answer === 2) {
                    plan_media_type_answer = '头条';
                    //推广计划类型
                    if (data.data.data.plan.promote_type_answer === 1) {
                        promote_type_answer = '京东定向推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'CPM';
                        }
                        if (data.data.data.plan.offer_type_answer === 3) {
                            offer_type_answer = 'tCPA';
                        }
                        if (data.data.data.plan.offer_type_answer === 4) {
                            offer_type_answer = 'eCPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 5) {
                            offer_type_answer = 'eUV';
                        }
                        //每日限额
                        if (data.data.data.plan.limit_answer === '0') {
                            limit_answer = '不限制';
                        } else {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                    }
                    if (data.data.data.plan.promote_type_answer === 2) {
                        promote_type_answer = '头条定向推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'tCPA';
                        }
                        //每日限额
                        if (data.data.data.plan.limit_answer) {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                        //高级规则
                        if (data.data.data.plan.advanced_rules_answer === 1) {
                            advanced_rules_answer = '匀速投放';
                        }
                        if (data.data.data.plan.advanced_rules_answer === 2) {
                            advanced_rules_answer = '加速投放';
                        }
                    }
                    //投放日期
                    if (data.data.data.plan.put_date_answer === 'all') {
                        put_date_answer = '不限制';
                    } else {
                        put_date_answer = (data.data.data.plan.put_date_answer).split('#')[0] + '至' + (data.data.data.plan.put_date_answer).split('#')[1];
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time_answer) {
                        if (data.data.data.plan.put_time_answer === 'all') {
                            put_time_answer = '全时间段投放';
                        } else {
                            put_time_answer = '在特定时间投放';
                        }
                    }

                }
                //百度
                if (data.data.data.plan.media_type_answer && data.data.data.plan.media_type_answer === 3) {
                    plan_media_type_answer = '百度';
                    //推广计划类型正确答案
                    if (data.data.data.plan.promote_type_answer === 1) {
                        promote_type_answer = '京东标签推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        //每日限额
                        if (data.data.data.plan.limit_answer) {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                    }
                    if (data.data.data.plan.promote_type_answer === 2) {
                        promote_type_answer = '百度标签推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'tCPA';
                        }
                        //每日限额
                        if (data.data.data.plan.limit_answer) {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                    }
                    if (data.data.data.plan.promote_type_answer === 3) {
                        promote_type_answer = '京东定向推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'CPM';
                        }
                        if (data.data.data.plan.offer_type_answer === 3) {
                            offer_type_answer = 'tCPA';
                        }
                        if (data.data.data.plan.offer_type_answer === 4) {
                            offer_type_answer = 'eUV';
                        }
                        //每日限额
                        if (data.data.data.plan.limit_answer === '0') {
                            limit_answer = '不限制';
                        } else {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                    }
                    //投放日期
                    if (data.data.data.plan.put_date_answer === 'all') {
                        put_date_answer = '不限制';
                    } else {
                        put_date_answer = (data.data.data.plan.put_date_answer).split('#')[0] + '至' + (data.data.data.plan.put_date_answer).split('#')[1];
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time_answer) {
                        if (data.data.data.plan.put_time_answer === 'all') {
                            put_time_answer = '全时间段投放';
                        } else {
                            put_time_answer = '在特定时间投放';
                        }
                    }
                }
                //快手
                if (data.data.data.plan.media_type_answer && data.data.data.plan.media_type_answer === 4) {
                    plan_media_type_answer = '快手';
                    //推广计划类型正确答案
                    if (data.data.data.plan.promote_type_answer === 1) {
                        arr_kuaishou[1].answers = '京东定向推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'tCPA';
                        }
                        if (data.data.data.plan.offer_type_answer === 3) {
                            offer_type_answer = 'eUV';
                        }
                        //每日限额
                        if (data.data.data.plan.limit_answer === '0') {
                            limit_answer = '不限制';
                        } else {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                    }
                    if (data.data.data.plan.promote_type_answer === 2) {
                        arr_kuaishou[1].answers = '快手定向推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type_answer === 1) {
                            offer_type_answer = 'CPC';
                        }
                        if (data.data.data.plan.offer_type_answer === 2) {
                            offer_type_answer = 'tCPA';
                        }
                        //每日限额
                        if (data.data.data.plan.limit_answer) {
                            limit_answer = data.data.data.plan.limit_answer + '元';
                        }
                        //高级规则
                        if (data.data.data.plan.advanced_rules_answer === 1) {
                            advanced_rules_answer = '匀速投放';
                        }
                        if (data.data.data.plan.advanced_rules_answer === 2) {
                            advanced_rules_answer = '加速投放';
                        }
                    }
                    //投放日期
                    if (data.data.data.plan.put_date_answer === 'all') {
                        put_date_answer = '不限制';
                    } else {
                        put_date_answer = (data.data.data.plan.put_date_answer).split('#')[0] + '至' + (data.data.data.plan.put_date_answer).split('#')[1];
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time_answer) {
                        if (data.data.data.plan.put_time_answer === 'all') {
                            put_time_answer = '全时间段投放';
                        } else {
                            put_time_answer = '在特定时间投放';
                        }
                    }
                }

                //学生提交答案
                let plan_media_type_stu_answers = '';//媒体类型
                let promote_type_stu_answers = '';//推广计划类型
                let offer_type_stu_answers = '';//出价方式
                let put_date_stu_answers = '';//投放日期
                let put_time_stu_answers = '';//每日投放时间
                let limit_stu_answers = '';//每日限额
                let advanced_rules_stu_answers = '';//高级规则
                //腾讯
                if (data.data.data.plan.media_type === 1 && data.data.data.plan.promote_type === 1) {
                    plan_media_type_stu_answers = '腾讯';
                    promote_type_stu_answers = '京东定向推广计划';
                    //出价方式
                    if (data.data.data.plan.offer_type === 1) {
                        offer_type_stu_answers = 'CPC';
                    }
                    if (data.data.data.plan.offer_type === 2) {
                        offer_type_stu_answers = 'CPM';
                    }
                    if (data.data.data.plan.offer_type === 3) {
                        offer_type_stu_answers = 'eUV';
                    }
                    //投放日期
                    if (data.data.data.plan.put_date) {
                        if (data.data.data.plan.put_date === 'all') {
                            put_date_stu_answers = '不限制';
                        } else {
                            put_date_stu_answers = (data.data.data.plan.put_date).split('#')[0] + '至' + (data.data.data.plan.put_date).split('#')[1];
                        }
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time === 'all') {
                        put_time_stu_answers = '全时间段投放';
                    } else {
                        put_time_stu_answers = '在特定时间投放';
                    }
                    //每日限额
                    if (data.data.data.plan.limit) {
                        limit_stu_answers = data.data.data.plan.limit;
                    } else {
                        limit_stu_answers = '不限制';
                    }
                }
                if ((data.data.data.plan.media_type === 1 && data.data.data.plan.promote_type === 2) ||
                    (data.data.data.plan.media_type === 1 && data.data.data.plan.promote_type === 4)) {
                    plan_media_type_stu_answers = '腾讯';
                    //推广计划类型
                    if (data.data.data.plan.promote_type === 2) {
                        promote_type_stu_answers = '无线广告推广计划';
                    }
                    if (data.data.data.plan.promote_type === 4) {
                        promote_type_stu_answers = 'PC广告推广计划';
                    }
                    //出价方式
                    if (data.data.data.plan.offer_type === 1) {
                        offer_type_stu_answers = 'CPC';
                    }
                    if (data.data.data.plan.offer_type === 2) {
                        offer_type_stu_answers = 'CPM';
                    }
                    //每日限额
                    if (data.data.data.plan.limit) {
                        limit_stu_answers = data.data.data.plan.limit + '元';
                    } else {
                        limit_stu_answers = '不限制';
                    }
                    //高级规则
                    if (data.data.data.plan.advanced_rules === 1) {
                        advanced_rules_stu_answers = '匀速投放';
                    }
                    if (data.data.data.plan.advanced_rules === 2) {
                        advanced_rules_stu_answers = '加速投放';
                    }
                }
                if (data.data.data.plan.media_type === 1 && data.data.data.plan.promote_type === 3) {
                    plan_media_type_stu_answers = '腾讯';
                    promote_type_stu_answers = '朋友圈计划';
                }
                //头条
                if (data.data.data.plan.media_type === 2 && data.data.data.plan.promote_type === 1) {
                    plan_media_type_stu_answers = '头条';
                    promote_type_stu_answers = '京东定向推广计划';
                    //出价方式
                    if (data.data.data.plan.offer_type === 1) {
                        offer_type_stu_answers = 'CPC';
                    }
                    if (data.data.data.plan.offer_type === 2) {
                        offer_type_stu_answers = 'CPM';
                    }
                    if (data.data.data.plan.offer_type === 3) {
                        offer_type_stu_answers = 'tCPA';
                    }
                    if (data.data.data.plan.offer_type === 4) {
                        offer_type_stu_answers = 'eCPC';
                    }
                    if (data.data.data.plan.offer_type === 5) {
                        offer_type_stu_answers = 'eUV';
                    }
                    //投放日期
                    if (data.data.data.plan.put_date) {
                        if (data.data.data.plan.put_date === 'all') {
                            put_date_stu_answers = '不限制';
                        } else {
                            put_date_stu_answers = (data.data.data.plan.put_date).split('#')[0] + '至' + (data.data.data.plan.put_date).split('#')[1];
                        }
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time === 'all') {
                        put_time_stu_answers = '全时间段投放';
                    } else {
                        put_time_stu_answers = '在特定时间投放';
                    }
                    //每日限额
                    if (data.data.data.plan.limit) {
                        limit_stu_answers = data.data.data.plan.limit;
                    } else {
                        limit_stu_answers = '不限制';
                    }
                }
                if (data.data.data.plan.media_type === 2 && data.data.data.plan.promote_type === 2) {
                    plan_media_type_stu_answers = '头条';
                    promote_type_stu_answers = '头条定向推广计划';
                    //出价方式
                    if (data.data.data.plan.offer_type === 1) {
                        offer_type_stu_answers = 'CPC';
                    }
                    if (data.data.data.plan.offer_type === 2) {
                        offer_type_stu_answers = 'CPM';
                    }
                    if (data.data.data.plan.offer_type === 3) {
                        offer_type_stu_answers = 'tCPA';
                    }
                    //投放日期
                    if (data.data.data.plan.put_date) {
                        if (data.data.data.plan.put_date === 'all') {
                            put_date_stu_answers = '不限制';
                        } else {
                            put_date_stu_answers = (data.data.data.plan.put_date).split('#')[0] + '至' + (data.data.data.plan.put_date).split('#')[1];
                        }
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time === 'all') {
                        put_time_stu_answers = '全时间段投放';
                    } else {
                        put_time_stu_answers = '在特定时间投放';
                    }
                    //每日限额
                    if (data.data.data.plan.limit) {
                        limit_stu_answers = data.data.data.plan.limit;
                    } else {
                        limit_stu_answers = '不限制';
                    }
                    //高级规则
                    if (data.data.data.plan.advanced_rules === 1) {
                        advanced_rules_stu_answers = '匀速投放';
                    }
                    if (data.data.data.plan.advanced_rules === 2) {
                        advanced_rules_stu_answers = '加速投放';
                    }
                }
                //百度
                if ((data.data.data.plan.media_type === 3 && data.data.data.plan.promote_type === 1) ||
                    (data.data.data.plan.media_type === 3 && data.data.data.plan.promote_type === 2) ||
                    (data.data.data.plan.media_type === 3 && data.data.data.plan.promote_type === 3)) {
                    plan_media_type_stu_answers = '百度';
                    //推广计划类型
                    if (data.data.data.plan.promote_type === 1 || data.data.data.plan.promote_type === 2) {
                        if (data.data.data.plan.promote_type === 1) {
                            promote_type_stu_answers = '京东标签推广计划';
                        }
                        if (data.data.data.plan.promote_type === 2) {
                            promote_type_stu_answers = '百度标签推广计划';
                        }
                        //出价方式
                        if (data.data.data.plan.offer_type === 1) {
                            offer_type_stu_answers = 'CPC';
                        }
                        if (data.data.data.plan.offer_type === 2) {
                            offer_type_stu_answers = 'CPM';
                        }
                        if (data.data.data.plan.offer_type === 3) {
                            offer_type_stu_answers = 'tCPA';
                        }
                    }
                    if (data.data.data.plan.promote_type === 3) {
                        promote_type_stu_answers = '京东定向推广计划';
                        //出价方式
                        if (data.data.data.plan.offer_type === 1) {
                            offer_type_stu_answers = 'CPC';
                        }
                        if (data.data.data.plan.offer_type === 2) {
                            offer_type_stu_answers = 'CPM';
                        }
                        if (data.data.data.plan.offer_type === 3) {
                            offer_type_stu_answers = 'tCPA';
                        }
                        if (data.data.data.plan.offer_type === 4) {
                            offer_type_stu_answers = 'eUV';
                        }
                    }
                    //投放日期
                    if (data.data.data.plan.put_date) {
                        if (data.data.data.plan.put_date === 'all') {
                            put_date_stu_answers = '不限制';
                        } else {
                            put_date_stu_answers = (data.data.data.plan.put_date).split('#')[0] + '至' + (data.data.data.plan.put_date).split('#')[1];
                        }
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time === 'all') {
                        put_time_stu_answers = '全时间段投放';
                    } else {
                        put_time_stu_answers = '在特定时间投放';
                    }
                    //每日限额
                    if (data.data.data.plan.limit) {
                        limit_stu_answers = data.data.data.plan.limit;
                    } else {
                        limit_stu_answers = '不限制';
                    }
                }
                //快手
                if (data.data.data.plan.media_type === 4 && data.data.data.plan.promote_type === 1) {
                    plan_media_type_stu_answers = '快手';
                    promote_type_stu_answers = '京东定向推广计划';
                    //出价方式
                    if (data.data.data.plan.offer_type === 1) {
                        offer_type_stu_answers = 'CPC';
                    }
                    if (data.data.data.plan.offer_type === 2) {
                        offer_type_stu_answers = 'tCPA';
                    }
                    if (data.data.data.plan.offer_type === 3) {
                        offer_type_stu_answers = 'eUV';
                    }
                    //投放日期
                    if (data.data.data.plan.put_date) {
                        if (data.data.data.plan.put_date === 'all') {
                            put_date_stu_answers = '不限制';
                        } else {
                            put_date_stu_answers = (data.data.data.plan.put_date).split('#')[0] + '至' + (data.data.data.plan.put_date).split('#')[1];
                        }
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time === 'all') {
                        put_time_stu_answers = '全时间段投放';
                    } else {
                        put_time_stu_answers = '在特定时间投放';
                    }
                    //每日限额
                    if (data.data.data.plan.limit) {
                        limit_stu_answers = data.data.data.plan.limit;
                    } else {
                        limit_stu_answers = '不限制';
                    }
                }
                if (data.data.data.plan.media_type === 4 && data.data.data.plan.promote_type === 2) {
                    plan_media_type_stu_answers = '快手';
                    promote_type_stu_answers = '快手定向推广计划';
                    //出价方式
                    if (data.data.data.plan.offer_type === 1) {
                        offer_type_stu_answers = 'CPC';
                    }
                    if (data.data.data.plan.offer_type === 2) {
                        offer_type_stu_answers = 'tCPA';
                    }
                    //投放日期
                    if (data.data.data.plan.put_date) {
                        if (data.data.data.plan.put_date === 'all') {
                            put_date_stu_answers = '不限制';
                        } else {
                            put_date_stu_answers = (data.data.data.plan.put_date).split('#')[0] + '至' + (data.data.data.plan.put_date).split('#')[1];
                        }
                    }
                    //每日投放时间
                    if (data.data.data.plan.put_time === 'all') {
                        put_time_stu_answers = '全时间段投放';
                    } else {
                        put_time_stu_answers = '在特定时间投放';
                    }
                    //每日限额
                    if (data.data.data.plan.limit) {
                        limit_stu_answers = data.data.data.plan.limit;
                    } else {
                        limit_stu_answers = '不限制';
                    }
                    //高级规则
                    if (data.data.data.plan.advanced_rules === 1) {
                        advanced_rules_stu_answers = '匀速投放';
                    }
                    if (data.data.data.plan.advanced_rules === 2) {
                        advanced_rules_stu_answers = '加速投放';
                    }
                }

                //完整字段(腾讯、头条、百度、快手数组)
                let arr_tengxun = [];
                let arr_toutiao = [];
                let arr_baidu = [];
                let arr_kuaishou = [];
                //腾讯
                if (media_type_question === 1 && promote_type_question === 1) {
                    arr_tengxun = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        },
                        {
                            question: '出价方式',
                            stu_answers: offer_type_stu_answers,
                            answers: offer_type_answer,
                            score: data.data.data.plan.offer_type_score
                        },
                        {
                            question: '投放日期',
                            stu_answers: put_date_stu_answers,
                            answers: put_date_answer,
                            score: data.data.data.plan.put_date_score
                        },
                        {
                            question: '每日投放时间',
                            stu_answers: put_time_stu_answers,
                            answers: put_time_answer,
                            score: data.data.data.plan.put_time_score
                        },
                        {
                            question: '每日限额',
                            stu_answers: limit_stu_answers,
                            answers: limit_answer,
                            score: data.data.data.plan.limit_score
                        }
                    ];
                    this.JDDirectList = arr_tengxun.concat(arr_publish);
                }
                if ((media_type_question === 1 && promote_type_question === 2) ||
                    (media_type_question === 1 && promote_type_question === 4)) {
                    arr_tengxun = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        },
                        {
                            question: '出价方式',
                            stu_answers: offer_type_stu_answers,
                            answers: offer_type_answer,
                            score: data.data.data.plan.offer_type_score
                        },
                        {
                            question: '每日限额',
                            stu_answers: limit_stu_answers,
                            answers: limit_answer,
                            score: data.data.data.plan.limit_score
                        },
                        {
                            question: '高级规则',
                            stu_answers: advanced_rules_stu_answers,
                            answers: advanced_rules_answer,
                            score: data.data.data.plan.advanced_rules_score
                        }
                    ];
                    this.JDDirectList = arr_tengxun.concat(arr_publish);
                }
                if (media_type_question === 1 && promote_type_question === 3) {
                    arr_tengxun = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        }
                    ];
                    this.JDDirectList = arr_tengxun.concat(arr_publish);
                }
                //头条
                if (media_type_question === 2 && promote_type_question === 1) {
                    arr_toutiao = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        },
                        {
                            question: '出价方式',
                            stu_answers: offer_type_stu_answers,
                            answers: offer_type_answer,
                            score: data.data.data.plan.offer_type_score
                        },
                        {
                            question: '投放日期',
                            stu_answers: put_date_stu_answers,
                            answers: put_date_answer,
                            score: data.data.data.plan.put_date_score
                        },
                        {
                            question: '每日投放时间',
                            stu_answers: put_time_stu_answers,
                            answers: put_time_answer,
                            score: data.data.data.plan.put_time_score
                        },
                        {
                            question: '每日限额',
                            stu_answers: limit_stu_answers,
                            answers: limit_answer,
                            score: data.data.data.plan.limit_score
                        }
                    ];
                    this.JDDirectList = arr_toutiao.concat(arr_publish);
                }
                if (media_type_question === 2 && promote_type_question === 2) {
                    arr_toutiao = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        },
                        {
                            question: '出价方式',
                            stu_answers: offer_type_stu_answers,
                            answers: offer_type_answer,
                            score: data.data.data.plan.offer_type_score
                        },
                        {
                            question: '投放日期',
                            stu_answers: put_date_stu_answers,
                            answers: put_date_answer,
                            score: data.data.data.plan.put_date_score
                        },
                        {
                            question: '每日投放时间',
                            stu_answers: put_time_stu_answers,
                            answers: put_time_answer,
                            score: data.data.data.plan.put_time_score
                        },
                        {
                            question: '每日限额',
                            stu_answers: limit_stu_answers,
                            answers: limit_answer,
                            score: data.data.data.plan.limit_score
                        },
                        {
                            question: '高级规则',
                            stu_answers: advanced_rules_stu_answers,
                            answers: advanced_rules_answer,
                            score: data.data.data.plan.advanced_rules_score
                        }
                    ];
                    this.JDDirectList = arr_toutiao.concat(arr_publish);
                }
                //百度
                if ((media_type_question === 3 && promote_type_question === 1) ||
                    (media_type_question === 3 && promote_type_question === 2) ||
                    (media_type_question === 3 && promote_type_question === 3)) {
                    arr_baidu = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        },
                        {
                            question: '出价方式',
                            stu_answers: offer_type_stu_answers,
                            answers: offer_type_answer,
                            score: data.data.data.plan.offer_type_score
                        },
                        {
                            question: '投放日期',
                            stu_answers: put_date_stu_answers,
                            answers: put_date_answer,
                            score: data.data.data.plan.put_date_score
                        },
                        {
                            question: '每日投放时间',
                            stu_answers: put_time_stu_answers,
                            answers: put_time_answer,
                            score: data.data.data.plan.put_time_score
                        },
                        {
                            question: '每日限额',
                            stu_answers: limit_stu_answers,
                            answers: limit_answer,
                            score: data.data.data.plan.limit_score
                        }
                    ];
                    this.JDDirectList = arr_baidu.concat(arr_publish);
                }
                //快手
                if (media_type_question === 4 && promote_type_question === 1) {
                    arr_kuaishou = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        },
                        {
                            question: '出价方式',
                            stu_answers: offer_type_stu_answers,
                            answers: offer_type_answer,
                            score: data.data.data.plan.offer_type_score
                        },
                        {
                            question: '投放日期',
                            stu_answers: put_date_stu_answers,
                            answers: put_date_answer,
                            score: data.data.data.plan.put_date_score
                        },
                        {
                            question: '每日投放时间',
                            stu_answers: put_time_stu_answers,
                            answers: put_time_answer,
                            score: data.data.data.plan.put_time_score
                        },
                        {
                            question: '每日限额',
                            stu_answers: limit_stu_answers,
                            answers: limit_answer,
                            score: data.data.data.plan.limit_score
                        }
                    ];
                    this.JDDirectList = arr_kuaishou.concat(arr_publish);
                }
                if (media_type_question === 4 && promote_type_question === 2) {
                    arr_kuaishou = [
                        {
                            question: '媒体类型',
                            stu_answers: plan_media_type_stu_answers,
                            answers: plan_media_type_answer,
                            score: data.data.data.plan.media_type_score
                        },
                        {
                            question: '推广计划类型',
                            stu_answers: promote_type_stu_answers,
                            answers: promote_type_answer,
                            score: data.data.data.plan.promote_type_score
                        },
                        {
                            question: '出价方式',
                            stu_answers: offer_type_stu_answers,
                            answers: offer_type_answer,
                            score: data.data.data.plan.offer_type_score
                        },
                        {
                            question: '投放日期',
                            stu_answers: put_date_stu_answers,
                            answers: put_date_answer,
                            score: data.data.data.plan.put_date_score
                        },
                        {
                            question: '每日投放时间',
                            stu_answers: put_time_stu_answers,
                            answers: put_time_answer,
                            score: data.data.data.plan.put_time_score
                        },
                        {
                            question: '每日限额',
                            stu_answers: limit_stu_answers,
                            answers: limit_answer,
                            score: data.data.data.plan.limit_score
                        },
                        {
                            question: '高级规则',
                            stu_answers: advanced_rules_stu_answers,
                            answers: advanced_rules_answer,
                            score: data.data.data.plan.advanced_rules_score
                        }
                    ];
                    this.JDDirectList = arr_kuaishou.concat(arr_publish);
                }
            },
            //查看商品主图
            viewGoodsImg() {
                this.goodsImgDialog = true;
                this.bigImg = this.socialECommerceList[0].cover;
            },
            //关闭商品主图弹窗
            handleCloseGoodsImgDialog() {
                this.goodsImgDialog = false;
            },
            //查看素材详情
            viewMaterialImg() {
                this.materialImgDialog = true;
            },
            //关闭素材弹窗
            handleCloseMaterialImgDialog() {
                this.materialImgDialog = false;
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;

        .text-content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .table-content {
            height: 100%;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .customTable {
                width: 100%;
                flex: 1;
                margin-top: 20px;

                .show-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .link-box {
                    margin-left: 20px;
                }
                ::v-deep tbody {
                    tr.el-table__row {
                        td:first-child .cell {
                            display: none;
                        }
                    }
                    tr.el-table__row:first-child {
                        td:first-child .cell {
                            display: block;
                        }
                    }
                }
            }

            .top-tab {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 2px solid #F1F5FF;

                .go-back {
                    margin-bottom: 12px;
                }
            }
        }
    }
    .goods-info {
        display: flex;
        align-items: center;
        .good-img {
            width: 80px;
            height: 80px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .info-right {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    .goods-img-dialog {
        .upload-file-content {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 445px;
            .big-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 444px;
                height: 444px;
                img {
                    max-width: 50%;
                    max-height: 50%;
                }
            }
        }
    }
      .mask {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        background-color: #000000;
        opacity: 0.6;
        }
        .videomasks {
            width: 800px;
            position: fixed;
            left: 50%;
            top: 50%;
            z-index: 20;
            transform: translate(-50%, -50%);
        }
        .videomasks img {
        width: 100%;
        height: 600px;
        background: #000;
        }
        .comment-top-btn {
            position: fixed;
            right: 0;
            top: 0;
            width: 16px;
            height: 16px;
            margin: 22px 20px 0 0;
            background: url('../../../../../assets/close_btn.png');
            cursor: pointer;
            // border: 1px solid red;
        }
</style>
